function initContactMap(container) {

    // var contact_map_settings = {
    //     center: { lat: 52.397661, lng: 17.000351 },
    //     zoom: 12,
    //     mapTypeControl: false,
    //     scrollwheel: false,
    //     disableDefaultUI: true
    // };

    // contact_map = new google.maps.Map(container, contact_map_settings);

    // contact_marker = new google.maps.Marker({
    //     position: { lat: 52.374661, lng: 17.075351 },
    //     map: contact_map,
    //     icon: kreacja_markers.settings.marker_url,
    // });

}


function initMaps() {
    
    var map_container = document.getElementById('map');

    if (map_container) {
        initContactMap(map_container);
    }

}

jQuery(document).ready(function($){

    initMaps();

});
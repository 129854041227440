// import 'slick-carousel'
import Helper from './helper';
import tippy from 'tippy.js'

tippy('.tooltip', {
	arrow: true,
	size: 'large',
	theme: 'rodo'
});

(function($){
    $(document).ready(function(){

		/****** SCROLL TO #HREF *******/
		(function(){

			var names = $('body').find('a[name]');
			names.each(function(index){
				$(this).attr('id',$(this).attr('name'));
			});

			function scrollable(element) {
				var baseuri = window.location.protocol+'//'+window.location.hostname+window.location.pathname;
				var link = element[0].href;
				link = link.replace(element[0].hash,'');
				link = link.replace('#','');
				if (link.charAt(link.length -1) != '/') {
					link = link+'/';
				};

				// if (link != baseuri) return false;
				if (element.hasClass('ui-tabs-anchor')) return false;
				if (element.hasClass('vc-carousel-control')) return false;
				if (element.closest('.tr-prevnext').length) return false;
				if (element.closest('h3').hasClass('wpb_accordion_header') ) return false;

				return true;
			}

			function scrollToCallback(e){

				if (scrollable($(this)))
				{
					var offset =  100;
					var item = $(this.hash);
					var h = 0;
					var w = 0;
					if (item.length > 0) {
						h = item.offset().top;
						h -= offset;
						w = item.offset().left;
					};
					$('html,body').scrollTo(w,h,{animation:{duration:900, easing:'swing'}});
					e.preventDefault();
				}
			}

			setTimeout(() => {
				$('a[href*="#"]').on('click',scrollToCallback);
			}, 1000);

		 })();

	});

	 const floatLabel = () => {

		let labels = document.querySelectorAll('form label'),
			fields = document.querySelectorAll('form input, form textarea');

		fields.forEach(function(e) {
			if (e.value != "") {
				$(e).closest('.form-field').addClass('active');
			}

			e.addEventListener('click', function() {
				$(this).closest('.form-field').addClass('active');
			});
		})

		fields.forEach(function(e) {
			e.addEventListener('blur', function() {
				if (this.value == "") {
					$(this).closest('.form-field').removeClass('active');
				}
			});
		})

	}
	floatLabel();

   	$(window).load(function(){

		/****** BODY PADDING TOP *******/
		(function(){

			function setBodyPaddingTop() {
				$('body').css('padding-top', $('.site-header').outerHeight());
			}
			setBodyPaddingTop();
			$(window).resize(setBodyPaddingTop);

		})();

		/****** STICKY HEADER *******/
		(function(){

			function add_header_class() {
				var window_top = $(window).scrollTop();

				if (0 < window_top) {
					$('.site-header').addClass('sticky');
				} else {
					$('.site-header').removeClass('sticky');
				}
			}

			$(window).on('scroll', add_header_class);

		})();

		/****** SLIDERS *******/
		let sliders = {
			offer : $('.offer-slider')
		}

		sliders.offer.slick({
			dots: true,
			speed: 1000,
			infinite: true,
			arrows: true,
			autoplay: true,
			dots: false,
			slidesToShow: 3,
			pauseOnFocus: false,
			prevArrow: $('.offer-slider-nav.prev'),
			nextArrow: $('.offer-slider-nav.next'),
			responsive: [
			{
				breakpoint: 1100,
				settings: {
					slidesToShow: 1,
				}
			},
			{
				breakpoint: 760,
				settings: {
					slidesToShow: 1,
				}
			},
			{
				breakpoint: 440,
				settings: {
					slidesToShow: 1,
				}
			}
			]
		});

	});

})(jQuery);
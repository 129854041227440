/**
 * navigation.js
 *
 * Handles toggling the navigation menu for small screens.
 */

import Helper from './helper.js';

const toggleMenu = () => {
	var button = document.getElementById('toggle'),
		container = document.getElementById('site-navigation'),
		html = document.querySelector('html');

		if (button && container) {
			button.addEventListener('click', () => {

				if(button.classList.contains('open')) {
					container.classList.remove('open');
					button.classList.remove('open');
					html.style.overflowY = 'auto';		
					return;
				}

				container.classList.add('open');
				button.classList.add('open');

				if (getScreenHeight() <= 300) {
					html.style.overflowY = 'hidden';
				} else {
					html.style.overflowY = 'auto';					
				}
	
			}) 
		}
} 
toggleMenu();

const closeMenu = () => {
	let links = document.querySelectorAll('.main-navigation a');
	let nav = document.getElementById('site-navigation');
	let toggle = document.getElementById('toggle');

	links.forEach( (e) => {
		e.addEventListener('click', () => {
			nav.classList.remove('open');
			toggle.classList.remove('open');
		})
	})
}

const addSticky = () => {
	let toggle = document.getElementById('toggle'),
		header = document.getElementById('masthead');

	toggle.addEventListener('click', () => {
		header.classList.add('sticky');
	})
	
}

if (Helper.isMobile()) {
	closeMenu();
	addSticky();
}

window.onresize = () => {
	if (Helper.isMobile()) {
		closeMenu();
		addSticky();
	}
};

